<template>
  <v-container>
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: "login",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "Login";
</style>
